// main : stylesheet.scss
#footer {
    margin: 0;
    background: $purple;
    position: relative;
    font-size: 15px;
    color: #fff;
    font-weight: 500;
    text-align: left;



    a {
        color: #fff;

        &:hover {
            color: $orange;
        }
    }



    .social {
        li {
            padding: 0;
            margin-right: 15px;

            a {


                .iconify {
                    color: #fff;
                    font-size: 30px;
                    transition: 0.3s all cubic-bezier(0.19, 1, 0.22, 1);
                }

                &:hover {
                    .iconify {
                        color: $orange;
                    }
                }
            }
        }
    }
 
    .footer-menu {
        column-count: 1;
        column-gap: 5rem;

        li {
            margin-bottom: 1rem;
        }

        @media (min-width: 576px) {
            column-count: 2;
        }

        @media (min-width: 992px) {
            column-count: 3;
        }
    }


    .copyright {
        background-color: #ffffff;
        margin-top: 5%;
        color: #676767;
        padding: 30px 0 40px;
        font-size: 12px;

        p {
            margin-bottom: 5px;
        }
    }

}