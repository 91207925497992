.section,
.page {
    padding: 40px 0px;
    position: relative;
    z-index: 2;

    @media (min-width: 576px) {
        padding: 40px 0;
    }

    @media (min-width: 768px) {
        padding: 50px 0;
    }

    @media (min-width: 992px) {
        padding: 60px 0;
    }

    @media (min-width: 1200px) {
        padding: 80px 0;
    }

    

    .section-title {
        color: $black;
        display: block;
        margin-bottom: 40px;
        position: relative;
        font-size: 25px;
        text-align: center;
        @media(min-width: 576px) {
            font-size: 37px;
        }

        @media(min-width: 768px) {
            font-size: 29px;
           
        }

        @media(min-width: 992px) {
            font-size: 31px;
          
        }

        @media(min-width: 1200px) {
            font-size: 34px;
        }

         
    }



    &.white {
        background-color: #fff;

    }


    &.lightblue{
        background: $lightblue;
    }


}