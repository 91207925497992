// main : stylesheet.scss
.menu-toggle {

    background: #fff;
    width: 50px;
    height: 50px;
    flex-basis: 50px;
    border-radius: 5px;
    cursor: pointer;

    .line {
        display: block;
        height: 3px;
        background: $black;
        width: 30px;
        margin-bottom: 5px;
        opacity: 1;
        transition: 0.3s all cubic-bezier(0.075, 0.82, 0.165, 1);
        border-radius: 3px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &:hover {
        .line{
            background-color: $orange;
        }
    }

}

.menu-container {


    position: relative;
    z-index: 25;
    flex-grow: 1;

    .main-menu {

        margin-left: 20px;
        position: relative;
        z-index: 10;
        margin-bottom: 0;


        >div {
            flex: 0 0 100%;
            max-width: 100%;
        }

        >div>ul {
            display:flex;
            justify-content: center;
            flex-wrap: wrap;
            align-items: center;
            width: auto;
            flex-basis: 100%;
            list-style: none;
            margin: 0;
            padding: 0;
            max-width: 100%;
            flex-direction: row;
            column-gap: 2.3rem;
            @media all and (-ms-high-contrast: none),
            (-ms-high-contrast: active) {
                display: block;
            }


            >li {
                display: flex;
                align-items: stretch;
                position: relative;
                max-width: 100%;
                flex: 0 1 auto;
                margin: 1px 0;

                @media all and (-ms-high-contrast: none),
                (-ms-high-contrast: active) {
                    display: inline-block;
                }


 

                >a,
                .menu-item {
                    color: #ffffff;
                    cursor: pointer;
                    font-weight: 600;
                    padding: 1rem;
                    display: block;
                    align-items: center;
                    position: relative;
                    flex: 0 1 100%;
                    overflow: hidden;
                    border-radius: 10px;
                    border: 1px solid transparent;


                    &:hover {
                        border: 1px solid #fff;

                    }

                }



                .menu-item {
                    position: relative;
                    white-space: nowrap;
                }


            }
        }
    }
}

.mobile-menu-container {


    z-index: 105;


    position: fixed;
    right: 0;
    top: 0;
    width: 250px;
    flex-basis: 250px;
    height: 100%;
    flex-basis: 100%;
    background: #fff;
    overflow-y: auto;
    display: flex;

    @media (min-width: 576px) {
        width: 300px;
        flex-basis: 300px;
    }

    align-items: flex-start;
    justify-content: flex-start;
    transform: translateX(100%);

    transition: 0.3s all cubic-bezier(0.075, 0.82, 0.165, 1);



   


    .main-menu {

        flex-basis: 100%;
        max-width: 100%;

        padding: 2rem 3rem;



        position: relative;
        z-index: 10;
        margin-bottom: 0;

        >div {
            flex: 0 0 100%;
            max-width: 100%;
        }

        >div>ul {

            align-items: center;
            justify-content: flex-end;
            width: 100%;
            flex-basis: 100%;
            list-style: none;
            margin: 0;
            padding: 0;
            max-width: 100%;
            display: block;
            flex-direction: column;
            justify-content: flex-start;






            >li {

                display: flex;
                align-items: stretch;
                position: relative;
                max-width: 100%;
                flex: 0 1 auto;



                >
                .menu-item {
                    color: $black;
                    cursor: pointer;
                    font-weight: 600;
                    padding: 1.5rem 0;
                    display: block;
                    align-items: center;
                    position: relative;
                    flex: 0 1 100%;
                    overflow: hidden;
                    border-bottom: 1px solid $lightblue;


                    &:hover {
                        color: $orange;

                    }

                }



            }
        }
    }
}

.close-menu {
    height: 60px;
    width: 60px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center; 
    z-index: 104;
    position: fixed;
    right: 0;
    top: 0;
    background-color: $orange;
    transform: translateX(100%);
    border-bottom-left-radius: 10px;
    .iconify {
        font-size: 2.5em;
        color: #fff;
    }

}