// main : stylesheet.scss

#header{
    
   
    padding: 20px 0;
   
    //position: fixed;
    z-index: 20;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    transform: translateY(0);
    transition: 0.3s all cubic-bezier(0.075, 0.82, 0.165, 1);
    background-color: $purple;
    @media (min-width: 1200px) {
        //position: fixed;
    } 
    &:after{
        content: '';
        //background-color: transparent;
        //background-image: linear-gradient(rgba($purple,0.8), transparent);
        //transition: 0.3s background-color cubic-bezier(0.075, 0.82, 0.165, 1);
        top: 0;
        left: 0;
        bottom: 0;
        position: absolute;
        height: 150%;
        width: 100vw;
        z-index: 3;

        @media (min-width: 768px) {
            height: 100%;
        }
    }
   
    #logo{
        margin:0 ;
        position: relative;
        z-index: 20;
        width: 150px;
        @media (min-width: 1200px) {
            width: 166px;
        }
       
    }

    .right-section{
        position: relative;
        z-index: 20;
        
        .top-section{
            font-size: 0.9em;
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            
            justify-content: flex-end;
            align-items: center;
            @media (min-width: 1200px) {
                li{
                    margin-left: 4rem!important;
                }
            }

            li{
                margin-left: 3rem!important;
            }
            a:not(.button){
                color: #fff;
                &:hover{
                    color: $orange;
                }

                .iconify{
                    font-size: 1.2em;
                }
            }
        }
    }

    .search {
        position: relative;
        input.ghost {
            background-color: transparent;
            color: #fff;
            &::placeholder { 
                color: white;
                opacity: 1; 
            }
            &:focus {
                border-color: #fff;
                box-shadow: none;
            }
        }
        .iconify {
            color: #fff;
            font-size: 20px;
            position: absolute;
            right: 10px;
            top: 7px;
        }
    }
    
}