.item{
    box-shadow: 0 0 20px rgba(0,0,0,0.1);
    border-radius: 16px;
    overflow: hidden;
    background-color: #fff;
    margin-bottom: 30px;
    position: relative;
    > a {
        color: inherit;
    }
    img{
        height: 207px;
        width: 100%;
        object-fit: cover;
        object-position: 50% 50%;
        position: relative;
        z-index: 1;

        &.small-height{
            height: 200px;
        }
    }
    .info{
        padding: 1.5rem 3rem 3rem;
        line-height: 1.9;
        font-size: 14px;
        h3{
            font-size: 18px;
        }

       
    }
 
    .date{
        background-color: $purple;
        padding: 1.5rem 2rem;
        color: #fff;
        position: absolute;
        top: 0;
        left:0;
        z-index: 2;
        span{
            display: block;
            line-height: 1.1;
            text-align: center;
            font-weight: 600;

            font-size: 14px;

            &.day{
                font-size: 27px;
            }
        }

       


    }
}
.subpage {
    margin-bottom: 40px;
    .item {
        min-height: 100%;
    }
}