.slider-section {
    overflow: hidden;
    .container-fluid {
        position: relative;
        overflow: hidden;
    }


    .off-screen-slider{

        position: initial;

        /*important, allows container-fluid to be the relative*/
        .slide-item {
            width: 33.33333%;

            .item {
                height: 100%;
                margin-bottom: 0;
                img {
                    height: 220px;

                } 
            }
        }
    }

    .slick-slide {
        margin: 0 15px;
        display: flex;
        align-items: stretch;
        height: auto;
    }

    .slick-list {
        overflow: visible;
        /*important, allows overflow slides to be visible*/
        margin: 0 -15px;
    }

    .slick-track {
        display: flex;
        align-items: stretch;
    }

    .slick-next:before,
    .slick-prev:before {
        color: black;
    }

    .slick-next,
    .slick-prev {
        height: 100%;
        width: 150px;
        z-index: 2;
    }

    .slick-prev,
    .slick-prev:hover {
        left: 0;
        background-image: linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
    }

    .slick-next,
    .slick-next:hover {
        right: 0;
        background-image: linear-gradient(to left, #fff, rgba(255, 255, 255, 0));
    }

    .slick-next.slick-disabled:before,
    .slick-prev.slick-disabled:before {
        display: none;
    }

    .slider-dots {
        position: relative;
    }
    .slider-arrows{
        margin-top: 4rem;
        display: flex;
        justify-content: flex-end;
        align-items: stretch;
        .previous{
            margin-right: 3rem;
        }

        .previous, .next{
            &:hover{
                .iconify{
                    color: $orange;
                }
            }
        }
        .iconify{
            font-size: 2em;
        }
    }


}