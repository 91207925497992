.breadcrumbs-container{
    display: none;
    @media(min-width: 768px) {
        display: block;
    }

    .breadcrumb{
        background: none;
        border: 0;
        border-radius: 0;
        margin: 0;
        padding: 0;
        display: inline-flex;
        align-items: center;
        padding: 15px 0;
        font-weight: 500;
        color: $black;
        li{
            font-size: 13px;
            a{
                color: $black;
                font-weight: 500;
                &:hover{
                    color: $orange;
                }
            } 
            +li:before{
                content: '/';
                    color: $black;
                padding: 0 5px 0 10px;
            }
        }
    
    }
}