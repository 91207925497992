// main : stylesheet.scss
.spacer{
    margin: 25px 0;
    height: 1px;
    @media (min-width: 576px) {
        margin: 25px 0;
    }
    @media (min-width: 768px) {
        margin: 3% 0;
    }
}

.flex-grow-1{
    flex-grow: 1;
}


.cursor-pointer{
    cursor: pointer;
}

.position-relative{
    position: relative!important;
}
 
.sidebar-container{
    margin-top: 5rem;
    @media (min-width: 1200px) {
        margin-top: 0;
    }
}