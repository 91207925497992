.main-layout{
   

    @media (min-width: 1200px) {
        display: flex;
        justify-content: space-between;
    }

    .content{
        
        @media (min-width: 1200px) {
            flex: 0 50%;
            max-width: 50%;
        }
    }

    .sidebar-container{
         
        
        @media (min-width: 1200px) {
            flex: 0 33.33333%;
            max-width: 33.3333%;
        }
    }
}