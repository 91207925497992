// main : stylesheet.scss

.page-header{

    &:not(.home) {
        //padding: calc(4vmin + var(--header-height)) 0 9vmin;
        padding: calc(4vmin) 0 9vmin;
        //padding: 0;
    }

    &.home{
        &:after, &:before {
            display: none !important;
        }
        //padding: var(--header-height) 0 0 0;
        padding: 0;
        .carousel-control {
            height: 50px;
            width: 50px;
            font-size: 30px;
            background-image:none;
            background-color: #fff;
            color: #222;
            top: 50%;
            left: 50px;
            &.right {
                left: inherit;
                right: 50px;
            }
        }
        .carousel-inner .item {
            padding: 10px 0 13vmin;
            background-position: center;
            background-size: cover;
            border-radius: 0;
            @media (min-width: 768px) {
                padding: calc(4vmin + var(--header-height)) 0 12vmin;

            }
            @media (min-width: 991px) {
                padding: calc(6vmin + var(--header-height)) 0 14vmin;

            }
            @media (min-width: 1200px) {
                padding: 8vmin 0 8vmin;

            }
            .fit-img {
                position:absolute;
                left: 0;
                top: 0;
            }
        }

    }

    &.generic-page {
        
    }

    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    color: #fff;
    img{
        width: 100%;
        position: absolute;
        height: 100%;
        object-fit: cover;
        object-position: right top;
        z-index: 1;
        top: 0;
        left: 0;
    }
 
    &:before{
        content: '';
        background-color: rgba(#2521C1,0.39);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 2;
        mix-blend-mode: multiply;

        @media (min-width: 768px) {
            mix-blend-mode: color;
        }
    }

    &:after{
        content: '';
        background-image: linear-gradient(90deg, $purple, transparent);
        top: 0;
        left: 0;
        bottom: 0;
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 3;
        
        @media (min-width: 768px) {
            width: 60%;
        }
    }

    .home-header-info{
        position: relative;
        z-index: 4;

        p{
            font-size: 16px;
            font-weight: 500;
            @media (min-width: 768px) {
                font-size: 18px;
            }
        }
    }

}