.button {
    padding: 12px 20px;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    position: relative;
    font-weight: 600;
    font-size: 16px;

    text-align: center;
    overflow: hidden;
    color: #fff;

    transition: 0.3s all cubic-bezier(0.19, 1, 0.22, 1);

    &.blue {
        @include button-bg($blue);
    }


    &.lightblue {
        @include button-bg($lightblue);
    }

    &.orange {
        @include button-bg($orange);
    }

    &.white {
        background-size: 100% 200%;
        background-position: 100% 0%;
        background-color: #ffffff;
        background-image: linear-gradient(#ffffff 0%, #ffffff 50%, $orange 50%, $orange 100%);
        color: $black;
        .iconify {
           
            color: $black;
        }
       

        &:hover,
        &:focus {
            background-position: 100% 100%;

            color: #fff;

            .iconify {
           
                color: #fff;
            }
        }

      
   

    }

    &:hover,
        &:focus {

            color: #fff!important;

           
        }

    .iconify {
        font-size: 1.2em;
        margin-right: 1rem;
        color: #fff;
        display: inline-block;
    }
} 