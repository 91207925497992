.image-container {
    overflow: hidden;
    width: 100%;
    flex: 0 0 100%;
    position: relative;

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: none;
    }

    

    &.height{
        &:before{
            content: '';
            display: block;
            padding-top: 65%;;
        }
    }


} 