.donate-now{
    border-radius: 16px;
    overflow: hidden;
    .donate-image{
        flex: 0 0 50%;
        position: relative;
        img{
            object-fit: cover;
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: none;
        }
    }

    .info{
        padding: 10% 7%;
        @media (min-width: 992px) {
            padding: 12% 8%;
        }
        background-color: $purple;
        color: #fff;
    }

  
}

.sidebar-container{
    .donate-now{
        background: $blue;
        .donate-image{
            display: none;
        }
        .info{
            padding: 10%;
            background: $blue;
        }
    }
} 