:root {
    --ratio: 1.18;
    --base: 1;
    --base-em: calc(var(--base) * 1em);
    --base-px: calc(var(--base) * 16px);

    --ms0: 1;
    --ms1: var(--ratio);                    /* 1.2   */
    --ms2: calc(var(--ratio) * var(--ms1)); /* 1.44  */
    --ms3: calc(var(--ratio) * var(--ms2)); /* 1.728 */
    --ms4: calc(var(--ratio) * var(--ms3)); /* 2.074 */
    --ms5: calc(var(--ratio) * var(--ms4)); /* 2.488 */
    --ms6: calc(var(--ratio) * var(--ms5)); /* 2.986 */
    --ms7: calc(var(--ratio) * var(--ms6)); /* 3.583 */
  } 