// main : stylesheet.scss

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {

    .button {
        gap: 1rem;
    }



    .page-header {
        padding: 200px 0 80px;


        &.home {
            padding: 150px 0;

            @media (min-width: 768px) {
                padding: 180px 0;

            }

            @media (min-width: 991px) {
                padding: 200px 0 ;
 
            }

           
        }

        img{
            height: auto;
        }
    }
}