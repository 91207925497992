@import "../bootstrap/bootstrap-classes.scss";
@import "../bootstrap/bootstrap-grid.min.scss";
@import "../bootstrap/bootstrap.min.scss";


@mixin button-bg($colour) {
    background-size: 100% 200%;
    background-position: 100% 0%;
    background-color: $colour;
    background-image: linear-gradient($colour 0%, $colour 50%, darken($colour, 10%) 50%, darken($colour, 10%) 100%);
    &:hover,
    &:focus {
        background-position: 100% 100%;
    }
} 

@import "settings/variables";
 
@import "settings/colours";


 
@import "settings/classes";

@import "layout/header";
@import "layout/menu";
@import "layout/footer";
@import "layout/section";
@import "layout/main";


@import "modules/buttons";
@import "modules/page-header";
@import "modules/breadcrumbs";
@import "modules/item";
@import "modules/images";
@import "modules/donate";
@import "modules/slick";
@import "modules/newsletter";

@import "settings/ie";

html {

    height: -webkit-fill-available;
 
}

body {
    font-family: 'Montserrat';
    font-size: 16px;
    background: #ffffff;
    color: $black;
    line-height: 1.5;

    min-height: 100vh;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;

    @media (min-width: 992px) {

        line-height: 1.7;
    }

    @media (min-width: 1200px) {
        .container {
            max-width: 1340px;
        }
    }

    img {
        max-width: 100%;
    }

    #content {
        min-height: 0;
    }


    .description-text {
        p {
            margin-bottom: 1.5rem;
        }

        ol {
            list-style: none;
            padding-left: 15px;
        }

        ul {
            list-style: none;
            padding-left: 5px;

            margin-bottom: 25px;

            li {
                position: relative;
                padding-left: 20px;
                margin-bottom: 10px;


                &:before {
                    height: 6px;
                    width: 6px;
                    border-radius: 50%;
                    background: $purple;
                    content: '';
                    position: absolute;
                    top: 10px;
                    left: 0;
                }
            }
        }
    }

    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 600;
    }

    h6,
    .h6 {
        font-size: calc(var(--base-em) / var(--ms1));
    }

    h5,
    .h5 {
        font-size: calc(var(--base-em) * var(--ms0));
    }

    h4,
    .h4 {
        font-size: calc(var(--base-em) * var(--ms1));
    }

    h3,
    .h3 {
        font-size: calc(var(--base-em) * var(--ms2));
    }

    h2,
    .h2 {
        font-size: calc(var(--base-em) * var(--ms3));
    }

    h1,
    .h1 {
        font-size: calc(var(--base-em) * var(--ms4));
    }

    @media (min-width: 768px) {

        h3,
        .h3 {
            font-size: calc(var(--base-em) * var(--ms3));
        }

        h2,
        .h2 {
            font-size: calc(var(--base-em) * var(--ms4));
        }

        h1,
        .h1 {
            font-size: calc(var(--base-em) * var(--ms5));
        }
    }

    @media (min-width: 992px) {

        h2,
        .h2 {
            font-size: calc(var(--base-em) * var(--ms5));
        }

        h1,
        .h1 {
            font-size: calc(var(--base-em) * var(--ms6));
        }
    }

    @media (min-width: 1200px) {

        h1,
        .h1 {
            font-size: calc(var(--base-em) * var(--ms7));
        }
    }


    a {
        transition: 0.3s all cubic-bezier(0.19, 1, 0.22, 1);

        &:focus {
            text-decoration: none;
        }

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    @media (max-width: 1199.98px) {
        &.open-menu {

            &:before {
                content: '';
                background-color: rgba($blue, 0.8);
                position: fixed;
                z-index: 100;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                height: 100vh;
                width: 100vw;

            }

            .close-menu {
                transform: translateX(-250px);
                transition: 0.3s all cubic-bezier(0.075, 0.82, 0.165, 1);

                @media (min-width: 576px) {
                    transform: translateX(-300px);
                }

            }

            .mobile-menu-container {
                transform: translate(0);
            }

            @media all and (-ms-high-contrast: none),
            (-ms-high-contrast: active) {
                transition: 0s all cubic-bezier(0.075, 0.82, 0.165, 1);

                #header {
                    transform: translateX(-300px);
                }
            }

        }
    }


    &.scroll-up {



        #header {

            padding: 10px 0;

            transform: translateY(0);
            background-color: $purple;

            &:after {
                content: '';
                height: 100%;
            }


        }

    }

    &.scroll-down {

        #header {

            margin: 0 auto;
            transform: translateY(-100%);

            &:after {
                display: none;
            }
        }

    }

}

a {

    &:hover {
        color: $orange;
    }
}

#font-size-modal{
    .modal-header{
        border: 0;
    }
}

.font-size-button{
    transition: 0.3s all cubic-bezier(0.075, 0.82, 0.165, 1);
    &:hover{
        color: $orange;
    }
}