.newsletter-section{
    background: $blue;
    padding: 4rem 0;
    color: #fff;
    text-align: center;
    overflow: hidden;
    p{
        font-size: 18px;
        margin: 0;
        font-weight: 600;
        @media (min-width: 567px) {
            font-size: 22px;
        }
    }
    .button{
        flex: 0 0 auto;
        margin-top: 3rem;
    }

    @media (min-width: 768px) {
        text-align: left;
        padding: 2.5rem 0;
        .button{
            margin-top: 0;
            margin-left: 3rem;
        }
    }
} 